.pricing {
  width: 100%;
  padding: 6rem 4rem;
  background-color: rgb(229, 242, 208);
}
.card-container {
  max-width: 1480px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 60px;
}
.card {
  display: flex;
  border: 2px solid #eee;
  color: #eee;
  background-color: rgb(205, 217, 182);
  text-align: center;
  padding: 1rem;
  height: 100%;
  border-radius: 15px;

}
.card h4 {
  margin-top: 12px;

}

.card h3 {
  font-size: 1.4rem;
  padding: 1rem;
}
.card p {
  padding: 10px 0;
  text-align: start;
}

.btc {
  font-size: 2.4rem;
  font-weight: 600;
  padding: 1rem 0;
}
.card .btn {
  display: block;
  width: 60%;
  margin: 1rem auto;
  margin-left: 0px;
  
  
}
.card .btn:hover {
  color: black;
}

@media screen and (max-width: 1393px) {
  .card-container {
    max-width: 80%;
    margin: auto;
    grid-template-columns: 1fr;
  }
}




@media (min-width: 375px) and (max-width: 562px) {
  .pricing {
    flex-direction: column; 
    align-items: center;
  }

  .card-container {
    flex-direction: column; 
    align-items: center;
    width: 100%;
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%; 
    margin-bottom: 20px; 
    margin-left: auto;
  }

  .servics-icon {
    margin-right: 0; 
  }

  .card .right p {
    font-size: 14px; 
  }

  .btn {
    font-size: 10px; 
    padding: 8px 16px; 
  }
}


@media (max-width: 375px) {

  .pricing {
    width: 120%;
    padding: 3rem 0rem ;
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 0px;
  }

  .card-container {
    margin-top: -30px;
  }

  .btn {
    font-size: 12px; 
    padding: 6px 12px; 
  }

  .card .right p {
    font-size: 12px; 
  }
}




