/* .body-container{
    width: 100vh;
    min-height:100vh;
    display: grid;
    place-items: center;
}

.row{
    width: 100vh;
    max-width: 1170px;
    display: grid;
    grid-template-columns: (2,1fr);
    grid-gap: 40px;
    overflow-x: hidden;
    padding: 5rem 0;
    


}
.row .imgWrapper{
    overflow: hidden;
}
.row .imgWrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
} */
.body-container {
    width: 100%;
    min-height: 100vh;
    display: flex; /* Use flexbox to center align the content */
    justify-content: center;
    align-items: center;
}

.row {
    width: 100%;
    max-width: 1170px;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns */
    gap: 40px; /* Space between the image and content */
    padding: 5rem 0;
    overflow-x: hidden;
}

.row .imgWrapper {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.row .imgWrapper img {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;
    transition: 0.3s;
    border-radius: 8px; /* Optional: Add rounded corners */
}

.row .contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.row .content {
    padding: 0 1rem;
}

.row .content .textWrapper {
    display: block;
    text-align: left;
}

.row .content h2 {
    margin: 1rem 0;
    font-size: 2rem;
    color: #333;
}

.row .content p {
    margin: 1rem 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #555;
}

.row .content .btn {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.8rem 1.5rem;
    background-color: lightgreen;
    color: #000;
    text-decoration: none;
    border-radius: 5px;
    transition: 0.3s;
}

.row .content .btn:hover {
    background-color: rgb(88, 229, 88);
}


@media (max-width: 768px) {
    .row {
        grid-template-columns: 1fr; /* Stack the columns */
        gap: 20px;
    }

    .row .imgWrapper img {
        width: 80%; /* Make the image full width */
        height: auto;
    }

    .row .contentWrapper {
        text-align: center;
    }
}
