.mask{
    width: 100%;
    height: 100vh;
    position:relative;
}
.into-img{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}
.mask::after{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.8;

}
.hero{
    height: 100%;
    width: 100%;

}
.hero .content{
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    text-align: center;
}
.hero .content h1{
    font-size: 4rem;
    padding: 0.6rem 0 0.5rem;
}
.hero .content p{
    font-size:1.4rem ;
    font-weight: 200;
    text-transform: uppercase;
    padding-bottom: 1.5rem;
    color: #fff;
}
.content .btn{
    margin: 0.8rem 0.2rem;
}

@media screen and (max-width:640px){

    .hero .content h1 {
        font-size: 2rem;
    }
    .hero .content p{
        font-size: 1.4rem;
        
    }
    
}
@media (min-width: 375px) and (max-width: 481px){
    .hero{
        width: 120%;  
        overflow: hidden;    
    }
    .content{
        margin-left: 50px;
    }
    .content h1{
        font-size: 18px;
    }
    .content .btn{
        padding: 8px 15px;
        font-size: 14px;
        width: 90%;
        margin: 5px 0;
        box-sizing: border-box;
    }

    .content div{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
           

    }

    @media (min-width: 300px) and (max-width: 375px) {
        .mask{
            height: 120vh;
        }

        .hero{
            width: 120%;
            overflow: hidden;
            
        }
        
        .btn {
          padding: 6px 12px; 
          font-size: 12px;
          width: 100%;
          margin: 5px 0; 
        }
        .content{
         padding-top: 6rem;
        padding-left: 4rem;
        }
      
        .content h1 {
          font-size: 8px; 
        }
      
        .content p {
          font-size: 3px; 
        }
      
        .content div {
          display: flex;
          flex-direction: column; 
          align-items: center; 
        }
      }
      





