@import url(https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap);
*{
  box-sizing: border-box;
  margin:0 ;
  padding: 0;
  font-family:"Outfit", serif;
}
body{
  background:rgb(236, 252, 204);
}
h1,h4,p,a{
  color: rgb(76, 86, 99);
  text-decoration: none;
}
ul{
  list-style-type: none;
}
.btn{
  
  color: black;
  padding-left:12px ;
  padding-right:12px ;
  padding-top:12px ;
  padding-bottom: 12px;
  font-size: 1rem;
 
  background: rgb(194, 227, 129);
  border-radius: 20px;
  border: 1px solid rgba(50, 40, 40,0.4);
}
.btn-light{
  background: transparent;
  
  color: #fff;
}
.btn:hover{
  background: rgba(255, 255, 255, 0.2);
  color: black;
  transition: 0.3s;
}
.btn-light:hover{
  color: black;
}
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    position: fixed;
    width: 100%;
    height: 90px;
    z-index: 10;
   
}
.header-bg{
    background-color: rgba(0, 0, 0, 0.3);
    transition: 0.5s;
}
.nav-menu{
    display: flex;
    
}
.nav-menu li {
    padding: 0 1rem;
}
.nav-menu li a{
    font-size: 1.2rem;
    font-weight: 500;
    color: rgb(194, 227, 129)
}
.nav-menu li a:hover {
    color: white; 
}

.hamburger{
    display: none;
}

@media screen and (max-width:1040px) {
    .nav-menu{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        background:rgba(0, 0, 0, 0.9);
        position: absolute;
        top: 0;
        left: -100%;
        z-index: -3;
        transition: 0.3s;

    }
    .nav-menu.active{
        left: 0;
    }
    .nav-menu li{
        padding: 1rem 0;
    }
    .nav-menu li a{
        font-size: 2rem;

    }
    .hamburger{
        display: initial;
    }
    
}
.mask{
    width: 100%;
    height: 100vh;
    position:relative;
}
.into-img{
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    object-fit: cover;
}
.mask::after{
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #000;
    opacity: 0.8;

}
.hero{
    height: 100%;
    width: 100%;

}
.hero .content{
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
    text-align: center;
}
.hero .content h1{
    font-size: 4rem;
    padding: 0.6rem 0 0.5rem;
}
.hero .content p{
    font-size:1.4rem ;
    font-weight: 200;
    text-transform: uppercase;
    padding-bottom: 1.5rem;
    color: #fff;
}
.content .btn{
    margin: 0.8rem 0.2rem;
}

@media screen and (max-width:640px){

    .hero .content h1 {
        font-size: 2rem;
    }
    .hero .content p{
        font-size: 1.4rem;
        
    }
    
}
@media (min-width: 375px) and (max-width: 481px){
    .hero{
        width: 120%;  
        overflow: hidden;    
    }
    .content{
        margin-left: 50px;
    }
    .content h1{
        font-size: 18px;
    }
    .content .btn{
        padding: 8px 15px;
        font-size: 14px;
        width: 90%;
        margin: 5px 0;
        box-sizing: border-box;
    }

    .content div{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
           

    }

    @media (min-width: 300px) and (max-width: 375px) {
        .mask{
            height: 120vh;
        }

        .hero{
            width: 120%;
            overflow: hidden;
            
        }
        
        .btn {
          padding: 6px 12px; 
          font-size: 12px;
          width: 100%;
          margin: 5px 0; 
        }
        .content{
         padding-top: 6rem;
        padding-left: 4rem;
        }
      
        .content h1 {
          font-size: 8px; 
        }
      
        .content p {
          font-size: 3px; 
        }
      
        .content div {
          display: flex;
          flex-direction: column; 
          align-items: center; 
        }
      }
      






.footer {
  width: 100%;
  padding: 4rem 0;
  background-color: rgba(19, 19, 19, 0.8);
}
.footer-container {
  max-width: 1140px;
  color: white;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
}
.left {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.location {
  display: flex;
  margin-bottom: 0.8rem;
}
.location p {
  line-height: 30px;
}
.right {
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.social {
  margin-top: 1rem;
}
h4 {
  font-size: 1.2rem;
  padding-bottom: 0.7rem;
}
.email h4 {
  font-size: 1rem;
}
p {
  font-size: 1.2rem;
}
@media screen and (max-width: 640px) {
  .footer-container {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 480px) {
  .footer {
    width: 120%;
    overflow: hidden;
  }
  h4 {
    font-size: 0.8rem;
  }
  p {
    font-size: 0.8rem;
  }
}

.parent-container {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  background-color: #e5f3d0;
}
.parent-container h1 {
  text-align: center;
  margin-bottom: 45px;
}

.grid-container {
  width: 160vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 80px;
  gap: 80px;
}
.numbers {
  font-size: 50px;
  font-weight: 600;
  color: rgb(77, 86, 99);
}


.grid-item h4 {
  padding-top: 10%;
} 


.grid-item {
  background-color:  rgb(205, 217, 182);
  padding: 20px;
  text-align: center;
  border: 1px solid #ccc;
  height: 35vh;
  border-radius: 30px;
  overflow: hidden;
}

@media (max-width: 1200px) {
    .grid-container {
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 40px;
      gap: 40px;
    }
  
    .numbers {
      font-size: 40px; 
    }
  
    .grid-item {
      height: 30vh;
    }
  }
  

  @media (max-width: 768px) {
    .parent-container {
      padding: 15px;
    }
  
    .grid-container {
      grid-template-columns: 1fr; 
      grid-gap: 30px; 
      gap: 30px;
    }
  
    .numbers {
      font-size: 35px;
      margin-bottom: 30px;
    }
  
    .grid-item {
      height: 25vh;
      padding: 0px; 
    }
    .grid-item h4{
        padding-top: 4%;
    }
    
}

@media (max-width: 480px) {
    .parent-container {
      padding: 20px;
      width: 110%;
      overflow: hidden;
    }
  
    .grid-container {
      grid-template-columns: 1fr;

      grid-gap: 20px;

      gap: 20px;
    }
  
    .numbers {
      font-size: 30px; 
    }
  
    .grid-item {
      height: 20vh;
      padding: 10px;
    }
  }
 
  @media (min-width: 300px) and (max-width: 375px) {
    .parent-container {
      padding: 20px;
      width: 120%;
      overflow: hidden;
    }
  
    .grid-container {
      width: 100%; 
      grid-template-columns: 1fr; 
      grid-gap: 20px; 
      gap: 20px; 
    }
  
    .numbers {
      font-size: 30px; 
    }
  
    .grid-item {
      height: 20vh; 
      padding: 10px; 
    }
  
    .grid-item h4 {
      padding-top: 5%; 
    }
  }

.pricing {
  width: 100%;
  padding: 6rem 4rem;
  background-color: rgb(229, 242, 208);
}
.card-container {
  max-width: 1480px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 60px;
}
.card {
  display: flex;
  border: 2px solid #eee;
  color: #eee;
  background-color: rgb(205, 217, 182);
  text-align: center;
  padding: 1rem;
  height: 100%;
  border-radius: 15px;

}
.card h4 {
  margin-top: 12px;

}

.card h3 {
  font-size: 1.4rem;
  padding: 1rem;
}
.card p {
  padding: 10px 0;
  text-align: start;
}

.btc {
  font-size: 2.4rem;
  font-weight: 600;
  padding: 1rem 0;
}
.card .btn {
  display: block;
  width: 60%;
  margin: 1rem auto;
  margin-left: 0px;
  
  
}
.card .btn:hover {
  color: black;
}

@media screen and (max-width: 1393px) {
  .card-container {
    max-width: 80%;
    margin: auto;
    grid-template-columns: 1fr;
  }
}




@media (min-width: 375px) and (max-width: 562px) {
  .pricing {
    flex-direction: column; 
    align-items: center;
  }

  .card-container {
    flex-direction: column; 
    align-items: center;
    width: 100%;
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 90%; 
    margin-bottom: 20px; 
    margin-left: auto;
  }

  .servics-icon {
    margin-right: 0; 
  }

  .card .right p {
    font-size: 14px; 
  }

  .btn {
    font-size: 10px; 
    padding: 8px 16px; 
  }
}


@media (max-width: 375px) {

  .pricing {
    width: 120%;
    padding: 3rem 0rem ;
  }

  .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 0px;
  }

  .card-container {
    margin-top: -30px;
  }

  .btn {
    font-size: 12px; 
    padding: 6px 12px; 
  }

  .card .right p {
    font-size: 12px; 
  }
}





.hero-img{
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    height: 65vh;
    position: relative;
    overflow: hidden;
}
.hero-img::before{
    content: "";
    background-image:url(/static/media/photo10.4f2d9c1e.jpg); 
    background-size : cover;
    background-position: center top;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: -1;
}

.heading{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.hero-img h1{
    font-size: 2.4rem;

}
.hero-img p{
    font-size: 1.4rem;
    text-align: center;
}

@media screen and (max-width:640px) {
    .hero-img h1 {
        font-size: 2rem;
    }
}

@media (min-width: 300px) and (max-width: 375px) {
    .hero-img {
        width: 120vw; 
        height: 60vh;
        background-color: rgba(0, 0, 0, 0.5);
        position: relative;
    }
    .hero-img::before {
        width: 120vw;
        background-size:cover;
        background-position: center;
    }
    .heading {
        padding: 0 1rem; 
    }
    .hero-img h1 {
        font-size: 1.8rem; 
    }
    .hero-img p {
        font-size: 1.2rem; 
        text-align: center;
        padding: 0 1rem; 
    }
}

.label{
    color: #fff;
    margin: 0.5rem;
}
.form{
    display: flex;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem;
    margin-top: 5%;
}


.contact-information{
    color: #f4f4f4;
    padding: 8rem 3rem;
    background-color: rgb(236, 252, 204);
    border-radius: 3%;
}
.contact-information{
    margin-top: 1rem;
    border-radius: 8px;
}


.contact-information p{
    margin-bottom: 5%;

}
.heading-contactinfo{

    font-size: 45px;
    font-weight: 600;
}



form{
    display: flex;
    flex-direction: column;
    padding: 3rem ;
    margin: auto;
    min-width: 35%;
    background-color: rgb(221, 228, 222);
    border-radius: 3%;
}
form input{
    color: black;
}

form input, form textarea{
    margin-bottom: 2rem;
    padding: 10px 18px;
    font-size: 1.2rem;
    background-color: rgba(255,255,255,0.1);
    border-color:rgba(47, 43, 43, 0.286) ;
    border-radius: 15px;
    /* color: #f4f4f4; */
}

.form .btn{
    width: 30%;
    align-self: flex-start;
}


@media screen and (max-width: 740px){
    .form {
        display: flex;
        flex-direction: column; /* Stack items vertically */
        align-items: center;
    }
    .contact-information,
    form {
        padding: 2rem;
        width: 90%; /* Full-width layout for smaller screens */
        margin-top: 1rem;
    }
    .heading-contactinfo {
        font-size: 32px; /* Adjust font size for smaller screens */
    }
}


@media (max-width: 375px) and (min-width: 300px) {
    .form {
        flex-direction: column;
        align-items: center;
        width: 110%;
        overflow: hidden;
    }

    .contact-information,
    form {
        padding: 1rem;
        width: 110%;
        margin-top: 1rem ;
        overflow: hidden;
    }

    .heading-contactinfo {
        font-size: 28px; /* Smaller font size for headings */
    }

    .contact-information p {
        font-size: 14px; /* Adjust paragraph font size */
    }

    form input,
    form textarea {
        font-size: 1rem; /* Reduce input font size */
        padding: 8px 12px; /* Smaller padding for inputs */
        margin-bottom: 1.5rem;
    }

    .form .btn {
        width: 100%;
        padding: 10px;
        font-size: 1rem; /* Adjust button font size */
    }
}


.wrapper {
    display: flex;
    justify-content: center;
    padding: 2rem;
    background-color: rgb(236, 252, 204);
}
.container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 6rem;
    gap: 6rem; 
    /* width: 100%; */
}


.box {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: 250px; 
    width: 250px;
    text-align: center;
}
.container h3{
    margin-left: 15px;
}


.logo {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    background-color: #e0e0e0; 
    padding: 1rem;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    text-align: center;
    margin-bottom: 0.5rem; 
}

 .info-text {
    display: flex;
    align-items: center;  
    grid-gap: 2rem;  
    gap: 2rem;  
}

@media (max-width: 942px) {
    .container {
        grid-template-columns: repeat(2, 1fr);  /* Stack in a single column */
        grid-gap: 2rem;
        gap: 2rem;  /* Reduce gap for smaller screens */
    }

    .box {
        width: 90%;  /* Make boxes take up more space on small screens */
        height: auto;  /* Allow boxes to grow as needed */
    }

    /* Reduce padding and font size for smaller screens */
    .logo {
        width: 60px;
        height: 60px;
        font-size: 1.2rem;
        padding: 0.8rem;
    }

    .container h3 {
        margin-left: 0;
        font-size: 1rem;
    }
}

/* For small screens (e.g., mobile), further adjustments */
@media (max-width: 560px) {
    .container {
        grid-template-columns:  1fr;  /* Stack in a single column */
        grid-gap: 2rem;
        gap: 2rem;  /* Reduce gap for smaller screens */
    }
    .wrapper {
        padding: 1rem;  /* Reduce padding for mobile */
        width: 100%;
        overflow: hidden;
    }

    .box {
        padding: 1.5rem;  /* Reduce box padding */
        width: 100%;  /* Make boxes narrower */
    }

    .logo {
        width: 50px;
        height: 50px;
        font-size: 1rem;
        padding: 0.6rem;
    }

}
@media (min-width: 300px) and (max-width: 375px) {
    .wrapper {
        padding: 1rem; 
        width: 120%; 
        overflow: hidden;
    }

    .container {
        grid-template-columns: 1fr;  
        grid-gap: 1.5rem;  
        gap: 1.5rem;  
    }

    .box {
        width:100%; 
        height: auto;  
        padding: 1.5rem; 
    }

    .logo {
        width: 50px;  
        height: 50px;
        font-size: 1rem;
        padding: 0.6rem;
    }

    .container h3 {
        margin-left: 0;
        font-size: 1rem;  
    }

    
    .box h3 {
        font-size: 1.1rem;
    }
}  
.label{
    color: #fff;
    margin: 0.5rem;
}
.form{
    display: flex;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    gap: 2rem;
    padding: 2rem;
    margin-top: 5%;
}


.contact-information{
    color: #f4f4f4;
    padding: 3rem 3rem;
    background-color: rgb(236, 252, 204);
    border-radius: 3%;
}
.contact-information{
    margin-top: 1rem;
    border-radius: 8px;
}


.contact-information p{
    margin-bottom: 5%;

}
.heading-contactinfo{

    font-size: 45px;
    font-weight: 600;
}



form{
    display: flex;
    flex-direction: column;
    padding: 3rem ;
    margin: auto;
    min-width: 35%;
    background-color: rgb(221, 228, 222);
    border-radius: 3%;
}
form input{
    color: black;
}

form input, form textarea{
    margin-bottom: 2rem;
    padding: 10px 18px;
    font-size: 1.2rem;
    background-color: rgba(255,255,255,0.1);
    border-color:rgba(47, 43, 43, 0.286) ;
    border-radius: 15px;
    /* color: #f4f4f4; */
}

.form .btn{
    width: 30%;
    align-self: flex-start;
}


@media screen and (max-width: 740px){
    .form {
        display: flex;
        flex-direction: column; /* Stack items vertically */
        align-items: center;
    }
    .contact-information,
    form {
        padding: 2rem;
        width: 90%; /* Full-width layout for smaller screens */
        margin-top: 1rem;
    }
    .heading-contactinfo {
        font-size: 32px; /* Adjust font size for smaller screens */
    }
}


@media (max-width: 375px) and (min-width: 300px) {
    .form {
        flex-direction: column;
        align-items: center;
        width: 110%;
        overflow: hidden;
    }

    .contact-information,
    form {
        padding: 1rem;
        width: 110%;
        margin-top: 1rem ;
        overflow: hidden;
    }

    .heading-contactinfo {
        font-size: 28px; /* Smaller font size for headings */
    }

    .contact-information p {
        font-size: 14px; /* Adjust paragraph font size */
    }

    form input,
    form textarea {
        font-size: 1rem; /* Reduce input font size */
        padding: 8px 12px; /* Smaller padding for inputs */
        margin-bottom: 1.5rem;
    }

    .form .btn {
        width: 100%;
        padding: 10px;
        font-size: 1rem; /* Adjust button font size */
    }
}

.overview-img img{
    width: 100%; 
    height: auto;
    border-radius: 8px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    margin-top: 20%;
    margin-left: 1%;
    display: block; 
} 
.overview{
    padding: 10px;
}
.overview-info{
    padding: 0;
    margin-left: 5%;

}
.project-heading{
    text-align: center;
    padding: 4rem 0 2rem 0;

}
.project-container{
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 40px;
    padding-bottom: 5rem;
   


}
.project-card{
    background: rgb(226, 236, 207);
    padding: 1.2rem 1rem;
    border-radius: 5%;
    height: 420px;
}
.project-card img {
    display: block;
    margin: 0 auto;
    width: 50%;
    height: 40%;
    border-radius: 50%;
    
}
.pro-details p{
    padding: 1rem 0rem;
    font-size: 1.1rem;
    text-align: justify;
}
.pro-btns{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;

}
 .pro-btns:hover{
    color: black;
}


@media screen and (max-width: 740px){
    .project-container{
        max-width: 90%;
        margin: auto;
        grid-template-columns: 1fr;

    }
    
}
/* .body-container{
    width: 100vh;
    min-height:100vh;
    display: grid;
    place-items: center;
}

.row{
    width: 100vh;
    max-width: 1170px;
    display: grid;
    grid-template-columns: (2,1fr);
    grid-gap: 40px;
    overflow-x: hidden;
    padding: 5rem 0;
    


}
.row .imgWrapper{
    overflow: hidden;
}
.row .imgWrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.3s;
} */
.body-container {
    width: 100%;
    min-height: 100vh;
    display: flex; /* Use flexbox to center align the content */
    justify-content: center;
    align-items: center;
}

.row {
    width: 100%;
    max-width: 1170px;
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two equal columns */
    grid-gap: 40px;
    gap: 40px; /* Space between the image and content */
    padding: 5rem 0;
    overflow-x: hidden;
}

.row .imgWrapper {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.row .imgWrapper img {
    width: 100%;
    height: auto; /* Maintain aspect ratio */
    object-fit: cover;
    transition: 0.3s;
    border-radius: 8px; /* Optional: Add rounded corners */
}

.row .contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.row .content {
    padding: 0 1rem;
}

.row .content .textWrapper {
    display: block;
    text-align: left;
}

.row .content h2 {
    margin: 1rem 0;
    font-size: 2rem;
    color: #333;
}

.row .content p {
    margin: 1rem 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #555;
}

.row .content .btn {
    display: inline-block;
    margin-top: 1rem;
    padding: 0.8rem 1.5rem;
    background-color: lightgreen;
    color: #000;
    text-decoration: none;
    border-radius: 5px;
    transition: 0.3s;
}

.row .content .btn:hover {
    background-color: rgb(88, 229, 88);
}


@media (max-width: 768px) {
    .row {
        grid-template-columns: 1fr; /* Stack the columns */
        grid-gap: 20px;
        gap: 20px;
    }

    .row .imgWrapper img {
        width: 80%; /* Make the image full width */
        height: auto;
    }

    .row .contentWrapper {
        text-align: center;
    }
}

