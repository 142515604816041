.hero-img{
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    height: 65vh;
    position: relative;
    overflow: hidden;
}
.hero-img::before{
    content: "";
    background-image:url("../assets/photo10.jpg"); 
    background-size : cover;
    background-position: center top;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: -1;
}

.heading{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.hero-img h1{
    font-size: 2.4rem;

}
.hero-img p{
    font-size: 1.4rem;
    text-align: center;
}

@media screen and (max-width:640px) {
    .hero-img h1 {
        font-size: 2rem;
    }
}

@media (min-width: 300px) and (max-width: 375px) {
    .hero-img {
        width: 120vw; 
        height: 60vh;
        background-color: rgba(0, 0, 0, 0.5);
        position: relative;
    }
    .hero-img::before {
        width: 120vw;
        background-size:cover;
        background-position: center;
    }
    .heading {
        padding: 0 1rem; 
    }
    .hero-img h1 {
        font-size: 1.8rem; 
    }
    .hero-img p {
        font-size: 1.2rem; 
        text-align: center;
        padding: 0 1rem; 
    }
}
