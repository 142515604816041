.label{
    color: #fff;
    margin: 0.5rem;
}
.form{
    display: flex;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    padding: 2rem;
    margin-top: 5%;
}


.contact-information{
    color: #f4f4f4;
    padding: 8rem 3rem;
    background-color: rgb(236, 252, 204);
    border-radius: 3%;
}
.contact-information{
    margin-top: 1rem;
    border-radius: 8px;
}


.contact-information p{
    margin-bottom: 5%;

}
.heading-contactinfo{

    font-size: 45px;
    font-weight: 600;
}



form{
    display: flex;
    flex-direction: column;
    padding: 3rem ;
    margin: auto;
    min-width: 35%;
    background-color: rgb(221, 228, 222);
    border-radius: 3%;
}
form input{
    color: black;
}

form input, form textarea{
    margin-bottom: 2rem;
    padding: 10px 18px;
    font-size: 1.2rem;
    background-color: rgba(255,255,255,0.1);
    border-color:rgba(47, 43, 43, 0.286) ;
    border-radius: 15px;
    /* color: #f4f4f4; */
}

.form .btn{
    width: 30%;
    align-self: flex-start;
}


@media screen and (max-width: 740px){
    .form {
        display: flex;
        flex-direction: column; /* Stack items vertically */
        align-items: center;
    }
    .contact-information,
    form {
        padding: 2rem;
        width: 90%; /* Full-width layout for smaller screens */
        margin-top: 1rem;
    }
    .heading-contactinfo {
        font-size: 32px; /* Adjust font size for smaller screens */
    }
}


@media (max-width: 375px) and (min-width: 300px) {
    .form {
        flex-direction: column;
        align-items: center;
        width: 110%;
        overflow: hidden;
    }

    .contact-information,
    form {
        padding: 1rem;
        width: 110%;
        margin-top: 1rem ;
        overflow: hidden;
    }

    .heading-contactinfo {
        font-size: 28px; /* Smaller font size for headings */
    }

    .contact-information p {
        font-size: 14px; /* Adjust paragraph font size */
    }

    form input,
    form textarea {
        font-size: 1rem; /* Reduce input font size */
        padding: 8px 12px; /* Smaller padding for inputs */
        margin-bottom: 1.5rem;
    }

    .form .btn {
        width: 100%;
        padding: 10px;
        font-size: 1rem; /* Adjust button font size */
    }
}
