.parent-container {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  background-color: #e5f3d0;
}
.parent-container h1 {
  text-align: center;
  margin-bottom: 45px;
}

.grid-container {
  width: 160vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 80px;
}
.numbers {
  font-size: 50px;
  font-weight: 600;
  color: rgb(77, 86, 99);
}


.grid-item h4 {
  padding-top: 10%;
} 


.grid-item {
  background-color:  rgb(205, 217, 182);
  padding: 20px;
  text-align: center;
  border: 1px solid #ccc;
  height: 35vh;
  border-radius: 30px;
  overflow: hidden;
}

@media (max-width: 1200px) {
    .grid-container {
      width: 100%;
      grid-template-columns: repeat(2, 1fr);
      gap: 40px;
    }
  
    .numbers {
      font-size: 40px; 
    }
  
    .grid-item {
      height: 30vh;
    }
  }
  

  @media (max-width: 768px) {
    .parent-container {
      padding: 15px;
    }
  
    .grid-container {
      grid-template-columns: 1fr; 
      gap: 30px;
    }
  
    .numbers {
      font-size: 35px;
      margin-bottom: 30px;
    }
  
    .grid-item {
      height: 25vh;
      padding: 0px; 
    }
    .grid-item h4{
        padding-top: 4%;
    }
    
}

@media (max-width: 480px) {
    .parent-container {
      padding: 20px;
      width: 110%;
      overflow: hidden;
    }
  
    .grid-container {
      grid-template-columns: 1fr;

      gap: 20px;
    }
  
    .numbers {
      font-size: 30px; 
    }
  
    .grid-item {
      height: 20vh;
      padding: 10px;
    }
  }
 
  @media (min-width: 300px) and (max-width: 375px) {
    .parent-container {
      padding: 20px;
      width: 120%;
      overflow: hidden;
    }
  
    .grid-container {
      width: 100%; 
      grid-template-columns: 1fr; 
      gap: 20px; 
    }
  
    .numbers {
      font-size: 30px; 
    }
  
    .grid-item {
      height: 20vh; 
      padding: 10px; 
    }
  
    .grid-item h4 {
      padding-top: 5%; 
    }
  }
