.project-heading{
    text-align: center;
    padding: 4rem 0 2rem 0;

}
.project-container{
    max-width: 1140px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 40px;
    padding-bottom: 5rem;
   


}
.project-card{
    background: rgb(226, 236, 207);
    padding: 1.2rem 1rem;
    border-radius: 5%;
    height: 420px;
}
.project-card img {
    display: block;
    margin: 0 auto;
    width: 50%;
    height: 40%;
    border-radius: 50%;
    
}
.pro-details p{
    padding: 1rem 0rem;
    font-size: 1.1rem;
    text-align: justify;
}
.pro-btns{
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;

}
 .pro-btns:hover{
    color: black;
}


@media screen and (max-width: 740px){
    .project-container{
        max-width: 90%;
        margin: auto;
        grid-template-columns: 1fr;

    }
    
}