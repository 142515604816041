@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

*{
  box-sizing: border-box;
  margin:0 ;
  padding: 0;
  font-family:"Outfit", serif;
}
body{
  background:rgb(236, 252, 204);
}
h1,h4,p,a{
  color: rgb(76, 86, 99);
  text-decoration: none;
}
ul{
  list-style-type: none;
}
.btn{
  
  color: black;
  padding-left:12px ;
  padding-right:12px ;
  padding-top:12px ;
  padding-bottom: 12px;
  font-size: 1rem;
 
  background: rgb(194, 227, 129);
  border-radius: 20px;
  border: 1px solid rgba(50, 40, 40,0.4);
}
.btn-light{
  background: transparent;
  
  color: #fff;
}
.btn:hover{
  background: rgba(255, 255, 255, 0.2);
  color: black;
  transition: 0.3s;
}
.btn-light:hover{
  color: black;
}