.overview-img img{
    width: 100%; 
    height: auto;
    border-radius: 8px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    margin-top: 20%;
    margin-left: 1%;
    display: block; 
} 
.overview{
    padding: 10px;
}
.overview-info{
    padding: 0;
    margin-left: 5%;

}